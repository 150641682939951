<template>
  <div>
    <load-profile></load-profile>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', 'role-permission')">
            <b-button
              variant="primary"
              :to="{ name: 'create-role-permission' }"
              >{{ $t("button.create") }}</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          type="role-permission"
        >
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import tableFields from "./tableField";
import searchInputs from "./searchInput";

const RoleRepository = Repository.get("role");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BLink,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
      },
    };
  },
  mounted() {
    this.getData(this.params.page);
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      RoleRepository.index({
        ...this.params,
        searchFields: this.searchFields,
      })
        .then((response) => {
          let data = response.data.data.list;
          this.items = [...data];
          this.total = response.data.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...tableFields];
    const searchFields = [...searchInputs];
    return {
      fields,
      searchFields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
